import { Fragment, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { useMutation } from "react-query";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@supabase/auth-helpers-react";

const navigation = [
  { name: "NYALA Asset Management", href: "/", external: false },
  /*   { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' }, */
  { name: "NYALA", href: "https://nyala.de/", external: true },
];
const features = [
  {
    name: "Track Portfolios",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
  {
    name: "No Data Hassle",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
  {
    name: "Synchonize Transaction",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
  {
    name: "Deploy Index Landing Pages",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
  {
    name: "Track Risk Measures",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
  {
    name: "Export Time Series",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.",
    icon: null,
  },
];
const blogPosts = [
  {
    id: 1,
    title:
      "Ride the trend and launch your luxury brand in the metaverse with NYALA! ",
    href: "https://www.nyala.de/2022/09/05/ride-the-trend-and-launch-your-luxury-brand-in-the-metaverse-with-nyala/",
    date: "Sep 5, 2022",
    datetime: "2022-09-05",
    category: { name: "Article", href: "#" },
    imageUrl:
      "https://www.nyala.de/wp-content/uploads/2022/09/cyberpunk-7408407_1280.jpg",
    preview:
      "Would you believe it if I told you that in the past few years, multiple fashion designers around the world have raised millions of dollars to sell clothes that do not exist? Or that an upstart fashion brand, RTFKT, sold 600 pairs of “phygital” NFT shoes for more than three million US dollars in under […]",
    author: {
      name: "William Bailey",
      imageUrl:
        "https://www.nyala.de/wp-content/uploads/2022/05/william-bailey.jpg",
      href: "#",
    },
    readingLength: "12 min",
  },
  {
    id: 2,
    title:
      "The upcoming Ethereum merge is not priced in, according to its founder.",
    href: "https://www.nyala.de/2022/08/22/the-upcoming-ethereum-merge-is-not-priced-in-according-to-its-founder/",
    date: "Aug 22, 2022",
    datetime: "2022-08-22",
    category: { name: "Article", href: "#" },
    imageUrl:
      "https://www.nyala.de/wp-content/uploads/2022/08/hardfork-ethereum-1024x0-c-default.jpeg",
    preview:
      "He is right; how could the merge already be priced in if most market participants have only a vague understanding of what it is? This post is intended to quickly bring you up to speed on what the Ethereum merge is and the different scenarios that might arise in its aftermath. As this is a […]",
    author: {
      name: "William Bailey",
      imageUrl:
        "https://www.nyala.de/wp-content/uploads/2022/05/william-bailey.jpg",
      href: "#",
    },
    readingLength: "8 min",
  },
  {
    id: 3,
    title: "Luna returns to Terra",
    href: "https://www.nyala.de/2022/05/18/luna-returns-to-terra/",
    date: "Mar 18, 2022",
    datetime: "2020-05-18",
    category: { name: "Article", href: "#" },
    imageUrl:
      "https://www.nyala.de/wp-content/uploads/2022/05/1-80M1TbTeasj9nV-zuDtaFw-2.png",
    preview:
      "If you are reading this, you have likely heard about and possibly felt the portfolio impact of Terra USD losing its peg to the US dollar in recent days. Students of the history of financial markets will observe that price pegs are like promises: they are often broken. This is doubly true of algorithmic stablecoins, […]",
    author: {
      name: "William Bailey",
      imageUrl:
        "https://www.nyala.de/wp-content/uploads/2022/05/william-bailey.jpg",
      href: "#",
    },
    readingLength: "11 min",
  },
];
export const footerNavigation = {
  solutions: [
    { name: "Custody", href: "https://www.nyala.de/en/custody/" },
    { name: "Tokenization", href: "https://www.nyala.de/en/tokenization/" },
    { name: "Asset Management", href: "/" },
    { name: "Trading", href: "https://www.nyala.de/en/trading/" },
  ],
  support: [
    { name: "Pricing", href: "/" },
    { name: "Documentation", href: "/" },
    { name: "Guides", href: "/" },
    { name: "API Status", href: "/" },
  ],
  company: [
    { name: "About", href: "https://www.nyala.de/en/about-2/" },
    {
      name: "Supervisory Board",
      href: "https://www.nyala.de/en/supervisory-board/",
    },
    { name: "Jobs", href: "https://nyala.jobs.personio.de/" },
    { name: "Blog", href: "https://nyala.de/blog/" },
  ],
  legal: [
    { name: "Contact", href: "/" },
    { name: "Imprint", href: "https://www.nyala.de/en/imprint/" },
    {
      name: "Data Protection",
      href: "https://www.nyala.de/en/data-protection/",
    },
  ],
  social: [
    /*   {
      name: 'Facebook',
      href: '#',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    }, */
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/nyalade",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/nyala_de",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/Kcustodian",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    /*  {
      name: 'Dribbble',
      href: '#',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
            clipRule="evenodd"
          />
        </svg>
      ),
    }, */
  ],
};

export default function Landing() {
  const user = useUser();

  const requestAccess = useMutation<any, any, { email: string }>(
    async ({ email }) => {
      const r = await axios.post("/api/request-access", {
        email,
      });
      return r.data;
    }
  );
  const emailRef = useRef<HTMLInputElement>(null);

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Popover as="header" className="relative">
          <div className="pt-6 bg-gray-800">
            <nav
              className="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link href="/">
                    <a>
                      <span className="sr-only">NYALA</span>
                      <img
                        className="w-auto h-8 sm:h-10 grayscale invert"
                        src={"/logo.png"}
                        alt=""
                      />
                    </a>
                  </Link>
                  <div className="flex items-center -mr-2 md:hidden">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded focus-ring-inset hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                      </svg>
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-8 md:ml-10 md:flex">
                  {navigation.map((item) => (
                    <Link key={item.name} href={item.href}>
                      <a className="flex items-center text-base font-medium text-white hover:text-gray-300">
                        {item.name}
                        {item.external ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4 ml-1 text-gray-400"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                          </svg>
                        ) : null}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden md:flex md:items-center md:space-x-6">
                {/* <Link href="/login">
                  <a className="text-base font-medium text-white hover:text-gray-300">
                    Log in
                  </a>
                </Link> */}
                {user ? (
                  <Link href="/dashboard">
                    <a className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-gray-600 border border-transparent rounded hover:bg-gray-700">
                      Goto Dashboard
                    </a>
                  </Link>
                ) : (
                  <Link href="/login">
                    <a className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-gray-600 border border-transparent rounded hover:bg-gray-700">
                      Login
                    </a>
                  </Link>
                )}
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 p-2 transition origin-top transform md:hidden"
            >
              <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="w-auto h-8" src={"/logo.png"} alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-main-600">
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link key={item.name} href={item.href}>
                        <a className="block px-3 py-2 text-base font-medium text-gray-900 rounded hover:bg-gray-50">
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>

                  {user ? (
                    <div className="px-5 mt-6">
                      <p className="text-base font-medium text-center text-gray-500">
                        <Link href="/dashboard">
                          <a className="text-gray-900 hover:underline">
                            Goto Dashboard
                          </a>
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="px-5 mt-6">
                        <Link href="/">
                          <a className="block w-full px-4 py-3 font-medium text-center text-white rounded shadow bg-gradient-to-r from-main-500 to-main-600 hover:from-main-600 hover:to-main-700">
                            Request Access
                          </a>
                        </Link>
                      </div>
                      <div className="px-5 mt-6">
                        <p className="text-base font-medium text-center text-gray-500">
                          Existing customer?{" "}
                          <Link href="/login">
                            <a className="text-gray-900 hover:underline">
                              Login
                            </a>
                          </Link>
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <main>
          <div className="pt-10 bg-gray-800 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                  <div className="lg:py-24">
                    <Link href="https://nyala.jobs.personio.de/">
                      <a
                        target="_blank"
                        className="inline-flex items-center p-1 pr-2 text-white bg-black rounded-full hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                      >
                        <span className="rounded-full bg-gradient-to-r from-main-500 to-main-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                          We&apos;re hiring
                        </span>
                        <span className="ml-4 text-sm">
                          Visit our careers page
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 ml-2 text-gray-500"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </a>
                    </Link>
                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block">A better way to</span>
                      <span className="block pb-3 text-transparent bg-gradient-to-r from-main-200 to-main-900 bg-clip-text sm:pb-5">
                        track portfolios
                      </span>
                    </h1>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      Create, Manage &amp; Track your portfolio and indices with
                      ease.
                    </p>
                    {!user ? (
                      <div className="mt-10 sm:mt-12">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (emailRef.current)
                              requestAccess.mutate({
                                email: emailRef.current.value,
                              });
                          }}
                          className="sm:mx-auto sm:max-w-xl lg:mx-0"
                        >
                          <div className="sm:flex">
                            <div className="flex-1 min-w-0">
                              <label htmlFor="email" className="sr-only">
                                Email address
                              </label>
                              <input
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border-0 rounded focus:outline-none focus:ring-2 focus:ring-main-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                ref={emailRef}
                              />
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                              <button
                                type="submit"
                                className="block w-full px-4 py-3 font-medium text-white rounded shadow bg-gradient-to-r from-main-500 to-main-600 hover:from-main-600 hover:to-main-700 focus:outline-none focus:ring-2 focus:ring-main-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                disabled={
                                  requestAccess.isLoading ||
                                  requestAccess.isSuccess
                                }
                              >
                                {requestAccess.isLoading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  "Request Access"
                                )}
                              </button>
                            </div>
                          </div>
                          <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                            {/* Start your free 14-day trial, no credit card
                          necessary. By providing your email, you agree to our{' '}
                          <a href="#" className="font-medium text-white">
                            terms of service
                          </a>
                          . */}
                            Once you&apos;ve requested access, we&apos;ll reach
                            out to you.
                          </p>
                        </form>
                      </div>
                    ) : (
                      <div className="mt-10 sm:mt-12">
                        <div className="sm:mx-auto sm:max-w-xl lg:mx-0">
                          <div className="sm:flex">
                            <div className="mt-3 sm:mt-0">
                              <Link href="/dashboard">
                                <a className="block w-full px-4 py-3 font-medium text-center text-white rounded shadow bg-gradient-to-r from-main-500 to-main-600 hover:from-main-600 hover:to-main-700 focus:outline-none focus:ring-2 focus:ring-main-400 focus:ring-offset-2 focus:ring-offset-gray-900">
                                  Start building your portfolio
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                  <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                    <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="/illustration.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Feature section with screenshot */}
          <div className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div>
                <h2 className="text-lg font-semibold text-main-600">
                  Portfolio &amp; Index Analytics
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  No more spreadsheets, no programming required.
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                  Create and track your portfolio via our intuitive interface.
                </p>
              </div>
              <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
                <img
                  className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                  //src="https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg"
                  src="/portfolio-view.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* Feature section with grid */}
          <div className="relative py-16 bg-white sm:py-24 lg:py-32">
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 className="text-lg font-semibold text-main-600">
                Analyze returns, risk, and more
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Get a full picture of your portfolio.
              </p>
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Analyze your portfolio&apos;s performance, risk, and more.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root px-6 pb-8 rounded-lg bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 rounded shadow-lg bg-gradient-to-r from-main-500 to-main-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                />
                              </svg>
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial section */}
          <div className="pb-16 bg-gradient-to-r from-main-500 to-main-600 lg:relative lg:z-10 lg:pb-0">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
              <div className="relative lg:-my-8">
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden"
                />
                <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                  <div className="overflow-hidden shadow-xl aspect-w-10 aspect-h-6 rounded-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img
                      className="object-cover lg:h-full lg:w-full"
                      //src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                      src="/showing.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
                <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                  <blockquote>
                    <div>
                      <svg
                        className="w-12 h-12 text-white opacity-25"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="mt-6 text-2xl font-medium text-white">
                        No more spreadsheets. No more manual calculations. No
                        more time wasted. I&apos;m finally able to see my
                        portfolio&apos;s performance in one place.
                      </p>
                    </div>
                    <footer className="mt-6">
                      <p className="text-base font-medium text-white">
                        Judith Black &amp; Wolfgang Meyer
                      </p>
                      <p className="text-base font-medium text-main-100">
                        CEO &amp; CFO at Crypto Inc.
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          {/* Blog section */}
          <div className="relative py-16 bg-gray-50 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-lg font-semibold text-main-600">Learn</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Helpful Resources
                </p>
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                  The Crypto market is a opaque and complex market. Read our
                  articles to learn more about the market and how to find your
                  way around.
                </p>
              </div>
              <div className="grid max-w-md gap-8 px-4 mx-auto mt-12 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                {blogPosts.map((post) => (
                  <div
                    key={post.id}
                    className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                  >
                    <div className="flex-shrink-0">
                      <img
                        className="object-cover w-full h-48"
                        src={post.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                      <div className="flex-1">
                        <p className="text-sm font-medium text-main-600">
                          <Link href={post.category.href}>
                            <a className="hover:underline">
                              {post.category.name}
                            </a>
                          </Link>
                        </p>
                        <Link href={post.href}>
                          <a className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">
                              {post.title}
                            </p>
                            <p className="mt-3 text-base text-gray-500">
                              {post.preview}
                            </p>
                          </a>
                        </Link>
                      </div>
                      <div className="flex items-center mt-6">
                        <div className="flex-shrink-0">
                          <Link href={post.author.href}>
                            <a>
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={post.author.imageUrl}
                                alt={post.author.name}
                              />
                            </a>
                          </Link>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            <Link href={post.author.href}>
                              <a className="hover:underline">
                                {post.author.name}
                              </a>
                            </Link>
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <time dateTime={post.datetime}>{post.date}</time>
                            <span aria-hidden="true">&middot;</span>
                            <span>{post.readingLength} read</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="relative bg-gray-800">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img
                className="object-cover w-full h-full"
                //src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                src="/showing-team.jpg"
                alt=""
              />
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gradient-to-r from-main-500 to-main-600 mix-blend-multiply"
              />
            </div>
            <div className="relative max-w-md px-4 py-12 mx-auto sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <h2 className="text-lg font-semibold text-gray-300">
                  Ready to dive in?
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  We are here to help
                </p>
                <p className="mt-3 text-lg text-gray-300">
                  Do you have any questions? We&apos;re here to help. Get in
                  touch with us and we&apos;ll get back to you as soon as we
                  can.
                </p>
                <div className="mt-8">
                  <div className="inline-flex rounded shadow">
                    <Link href="/">
                      <a className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded hover:bg-gray-50">
                        Contact us
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 ml-3 -mr-1 text-gray-400"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="bg-gray-50" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="max-w-md px-4 pt-12 mx-auto sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-8 xl:col-span-1">
                <img
                  className="h-10"
                  //src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=300"
                  src="/logo.png"
                  alt="NYALA"
                />
                <p className="text-base text-gray-500">
                  The all-in-one platform for digital assets
                </p>
                <div className="flex space-x-6">
                  {footerNavigation.social.map((item) => (
                    <Link key={item.name} href={item.href}>
                      <a
                        className="text-gray-400 hover:text-gray-500"
                        target="_blank"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="w-6 h-6" aria-hidden="true" />
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8 mt-12 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-base font-medium text-gray-900">
                      Solutions
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.solutions.map((item) => (
                        <li key={item.name}>
                          <Link href={item.href}>
                            <a className="text-base text-gray-500 hover:text-gray-900">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">
                      Support
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.support.map((item) => (
                        <li key={item.name}>
                          <Link href={item.href}>
                            <a className="text-base text-gray-500 hover:text-gray-900">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-base font-medium text-gray-900">
                      Company
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.company.map((item) => (
                        <li key={item.name}>
                          <Link href={item.href}>
                            <a className="text-base text-gray-500 hover:text-gray-900">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">
                      Legal
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name}>
                          <Link href={item.href}>
                            <a className="text-base text-gray-500 hover:text-gray-900">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-8 mt-12 border-t border-gray-200">
              <p className="text-base text-gray-400 xl:text-center">
                &copy; 2022 NYALA Digital Asset AG. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
