import create from 'zustand';
interface NotificationState {
  notifications: {
    title: string;
    description: string;
  }[];
  addNotification: (notification: {
    title: string;
    description: string;
  }) => void;
}

export const useNotifications = create<NotificationState>()((set) => ({
  notifications: [],
  addNotification: (notification) => {
    set((state) => ({
      notifications: [...state.notifications, notification],
    }));
  },
}));
