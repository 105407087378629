import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQuery } from "react-query";
import { Database } from "../types/supabase";

export const useUserData = (id?: string) => {
  const supabaseClient = useSupabaseClient<Database>();
  const q = useQuery(
    ["avatarUrl", id],
    async () => {
      const { data } = await supabaseClient.storage
        .from("avatar")
        .createSignedUrl(id + "/" + "avatar.png", 60 * 60 * 24);

      return data?.signedUrl;
    },
    {
      enabled: !!id,
    }
  );

  return q;
};
