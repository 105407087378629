import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChartLine,
  faCoins,
  faDatabase,
  faHome,
  faQuestion,
  faTowerCell,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import Link from "next/link";
import { useQuery } from "react-query";
import axios from "axios";
import { GitLabApiResponse } from "../pages/api/gitlab";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { useUserData } from "../queries/useUserData";
import { Database } from "../types/supabase";

const navigation = (path: string) => {
  return [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: (
        <FontAwesomeIcon
          icon={faHome}
          fixedWidth
          className={classNames(
            path === "/dashboard"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/dashboard",
    },
    {
      name: "Coins",
      href: "/asset",
      icon: (
        <FontAwesomeIcon
          icon={faCoins}
          fixedWidth
          className={classNames(
            path === "/asset"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/asset",
    },
    {
      name: "Portfolio",
      href: "/portfolio",
      icon: (
        <FontAwesomeIcon
          icon={faWallet}
          fixedWidth
          className={classNames(
            path === "/portfolio"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/portfolio",
    },
    {
      name: "Indices & Strategies",
      href: "/strategy",
      icon: (
        <FontAwesomeIcon
          icon={faChartLine}
          fixedWidth
          className={classNames(
            path === "/strategy"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/strategy",
    },
    /* {
      name: "Data Management",
      href: "/admin",
      icon: (
        <FontAwesomeIcon
          icon={faDatabase}
          fixedWidth
          className={classNames(
            path === "/admin"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/admin",
    }, */
    {
      name: "API Docs",
      href: "/api/p/docs",
      icon: (
        <FontAwesomeIcon
          icon={faTowerCell}
          fixedWidth
          className={classNames(
            path === "/api/p/docs"
              ? "text-gray-300"
              : "text-gray-400 group-hover:text-gray-300",
            "flex-shrink-0 h-6 w-6"
          )}
        />
      ),
      current: path === "/api/p/docs",
    },
    /* {
      name: 'Logout',
      href: '/api/auth/logout',
      icon: (
        <FontAwesomeIcon
          icon={faArrowRightFromBracket}
          fixedWidth
          className={classNames(
            path === '/login'
              ? 'text-gray-300'
              : 'text-gray-400 group-hover:text-gray-300',
            'flex-shrink-0 h-6 w-6'
          )}
        />
      ),
      current: path === '/login',
    }, */
  ];
};
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type SidebarProps = {
  children: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const router = useRouter();
  const supabaseClient = useSupabaseClient<Database>();
  const logout = async () => {
    const r = await supabaseClient.auth.signOut();
    console.log("logged out, redirecting");
    router.push("/product");
  };

  const navs = navigation(router.pathname);

  const user = useUser();

  const q = useUserData(user?.id);

  const git = useQuery(
    "git",
    async () => {
      const r = await axios.get<GitLabApiResponse>("/api/gitlab");
      return r.data;
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="w-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4">
                      <img
                        className="w-auto h-8 rounded grayscale invert"
                        src="/logo-white.png"
                        alt="NYALA"
                      />
                    </div>
                    <nav className="px-2 mt-5 space-y-1">
                      {navs.map((item) => (
                        <Link key={item.name} href={item.href}>
                          <a
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            {/*   <item.icon
                            className={classNames(
                              item.current
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          /> */}
                            {item.icon}
                            <span className="ml-4">{item.name}</span>
                          </a>
                        </Link>
                      ))}
                      <button
                        onClick={logout}
                        className="flex items-center w-full px-2 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white group"
                      >
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          fixedWidth
                          className={
                            "text-gray-400 group-hover:text-gray-300 flex-shrink-0 h-6 w-6"
                          }
                        />
                        <span className="ml-4">Logout</span>
                      </button>
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 p-4 bg-gray-700">
                    <Link href="/profile">
                      <a className="flex-shrink-0 block group">
                        <div className="flex items-center">
                          <div>
                            {q.data ? (
                              <img
                                className="inline-block w-10 h-10 rounded-full"
                                src={q.data}
                                alt=""
                              />
                            ) : null}
                          </div>
                          <div className="ml-3">
                            <p className="text-base font-medium text-white">
                              {user?.email}
                            </p>
                            <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                              View profile
                            </p>
                          </div>
                        </div>
                      </a>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-1 min-h-0 bg-gray-800">
            <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-auto h-8 rounded invert grayscale"
                  src="/logo-white.png"
                  alt="NYALA"
                />
              </div>
              <nav className="flex-1 px-2 mt-5 space-y-1">
                {navs.map((item) => (
                  <Link key={item.name} href={item.href}>
                    <a
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      {/*  <item.icon
                      className={classNames(
                        item.current
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    /> */}

                      {item.icon}
                      <span className="ml-3">{item.name}</span>
                    </a>
                  </Link>
                ))}
                <button
                  onClick={logout}
                  className={
                    "text-gray-300 w-full hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    fixedWidth
                    className={
                      "text-gray-400 group-hover:text-gray-300 flex-shrink-0 h-6 w-6"
                    }
                  />
                  <span className="ml-3">Logout</span>
                </button>
              </nav>
              {git.data ? (
                <>
                  <div className="flex flex-col items-center justify-center px-3 py-1 space-x-1 text-xs text-center text-gray-300 transition-colors rounded">
                    {git.data.deployedCommit ? (
                      <>
                        {git.data.deployedCommit.id ===
                        git.data.latestCommit.id ? (
                          <span className="mb-3">
                            This Deploy is the newest version of the main
                            branch.
                          </span>
                        ) : null}
                        <span>Latest Deploy</span>
                        <Link
                          href={`https://gitlab.com/nyalade/bachelier/-/commit/${git.data.deployedCommit.id}`}
                        >
                          <a target="_blank">
                            {new Date(
                              git.data.deployedCommit?.created_at
                            ).toLocaleString()}
                          </a>
                        </Link>
                      </>
                    ) : null}
                    <span>Latest Commit</span>
                    <Link
                      href={`https://gitlab.com/nyalade/bachelier/-/commit/${git.data.latestCommit.id}`}
                    >
                      <a target="_blank">
                        {new Date(
                          git.data.latestCommit.created_at
                        ).toLocaleString()}
                      </a>
                    </Link>
                  </div>
                </>
              ) : null}
            </div>
            {user ? (
              <div className="flex flex-shrink-0 p-4 bg-gray-700">
                <Link href="/profile">
                  <a className="flex-shrink-0 block w-full group">
                    <div className="flex items-center">
                      <div>
                        {q.data ? (
                          <img
                            className="inline-block rounded-full h-9 w-9"
                            src={q.data}
                            alt=""
                          />
                        ) : (
                          <div className="flex items-center justify-center text-white bg-gray-600 rounded-full h-9 w-9">
                            <FontAwesomeIcon icon={faQuestion} />
                          </div>
                        )}
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          {user.email}
                        </p>
                        <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1 w-full md:pl-64">
          <div className="sticky top-0 z-10 pt-1 pl-1 bg-gray-100 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <main className="flex-1">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
