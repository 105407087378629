import { setUser } from "@sentry/nextjs";
import { useUser } from "@supabase/auth-helpers-react";
import { ReactNode, useEffect, useState } from "react";
import Footer from "./Footer";
import NotificationArea from "./NotificationArea";
import Sidebar from "./Sidebar";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const user = useUser();
  const [userIsSet, setUserIsSet] = useState(false);

  useEffect(() => {
    if (user && !userIsSet) {
      setUser({
        id: user.id,
        email: user.email,
      });
      console.log("Setting sentry user:", user.id, user.email);
      setUserIsSet(true);
    }
  }, [user]);

  return (
    <div className="flex h-screen">
      <Sidebar>
        <div>{children}</div>
        <Footer />
      </Sidebar>
      <NotificationArea />
    </div>
  );
};

export default Layout;
